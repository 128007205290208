html {
    scroll-behavior: smooth;
}

.carousel-item {
}

.carousel-container {
    position:static !important;
    /* margin-right: -2.5rem; */
    /* padding-left: 2rem !important; */
    /* margin: 0 5rem; */
    /* overflow: visible !important; */
}

.react-multiple-carousel__arrow--right {
    position: absolute !important;
    right: 0rem !important;
    /* margin-left: 5rem !important; */
}
